<script setup lang="ts">
import { Collapse } from 'bootstrap'

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const isInstructionsOpen = ref(false)
const collapseElement = ref<string | Element>('')
let collapse: null | Collapse = null

function resetInput() {
  emit('update:modelValue', '')
}

function toggleCollapse(toggle = undefined) {
  if (typeof toggle === 'undefined') {
    isInstructionsOpen.value = !isInstructionsOpen.value
  }
  else {
    isInstructionsOpen.value = toggle
  }

  if (collapse) {
    if (isInstructionsOpen.value) {
      collapse.show()
    }
    else {
      collapse.hide()
    }
  }
}

onMounted(() => {
  collapse = new Collapse(
    collapseElement.value,
    { toggle: isInstructionsOpen.value },
  )
})
</script>

<template>
  <div class="mb-3">
    <div class="input-group">
      <span class="input-group-text">
        <i class="far fa-search" />
      </span>
      <button
        v-show="props.modelValue"
        class="floating-input-button btn btn-secondary rounded"
        @click="resetInput"
      >
        <i class="fa fa-backspace" />
      </button>
      <input
        :value="props.modelValue"
        class="form-control has-floating-input-button-xl"
        type="text"
        placeholder="Search for loggers…"
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      >
    </div>
    <div :class="{ 'instructions-open': isInstructionsOpen }">
      <button
        class="btn-open btn btn-sm btn-link text-secondary mt-1"
        @click="toggleCollapse()"
      >
        instructions<i class="fas fa-chevron-down ms-2" />
      </button>
      <div ref="collapseElement" class="collapse">
        <div class="card mt-1">
          <div class="card-body">
            Search for a loggers name, info, location, tags, mac address, or ip address. Multiple tags must be separated by commas (<code>,</code>) without spaces in between.<br><br>

            Example: <code>tag1,tag2,tag3</code> to lookup for a logger which has all of the three tags, or <code>Hohenzollernring 72</code> to search for a logger with that address.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
