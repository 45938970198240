import type { CommandDescription } from '@/types'
import type { User } from 'oidc-client-ts'

const headers = ref(new Headers())
const api_url = $settings.api_url ?? ''

export default function useApiService() {
  const setBearerToken = (newBearer: string) => {
    headers.value.set('Authorization', `Bearer ${newBearer}`)
  }

  const updateTokenFromUser = (newUser: User | undefined) => {
    if (newUser && newUser.access_token)
      setBearerToken(newUser.access_token)
  }

  const queryLoggers = () => {
    return fetch(`${api_url}/api/logger`, { headers: headers.value })
  }

  const apiRequest = async (request: Request, commandDescription: CommandDescription) => {
    let queryWentWrong = false

    try {
      const response = await fetch(request)

      if (!response.ok)
        queryWentWrong = true
    }
    catch (error) {
      queryWentWrong = true
      console.error(error)
    }

    if (queryWentWrong)
      console.error(`Couldn’t ${commandDescription} (see developer tools console or network tab)`)

    return !queryWentWrong
  }

  const startSsh = async (loggerMac: string) => {
    return await apiRequest(
      new Request(
        `${api_url}/api/logger/${loggerMac}/ssh/start`,
        {
          method: 'POST',
          headers: headers.value,
        },
      ),
      'start ssh',
    )
  }

  const stopSsh = async (loggerMac: string) => {
    return await apiRequest(
      new Request(
        `${api_url}/api/logger/${loggerMac}/ssh/stop`,
        {
          method: 'POST',
          headers: headers.value,
        },
      ),
      'stop ssh',
    )
  }

  const deleteLogger = async (loggerMac: string) => {
    return await apiRequest(
      new Request(
        `${api_url}/api/logger/${loggerMac}`,
        {
          method: 'DELETE',
          headers: headers.value,
        },
      ),
      'remove logger',
    )
  }

  return {
    headers,
    setBearerToken,
    updateTokenFromUser,
    queryLoggers,
    startSsh,
    stopSsh,
    deleteLogger,
  }
}
