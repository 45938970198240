<script setup lang="ts">
import type { LoggerType } from '../types'
import secondsToTimeFormat from '@/utils/helpers/secondsToTimeFormat'
import { fromUnixTime, lightFormat } from 'date-fns'

const props = defineProps<{
  logger: LoggerType
}>()

const emit = defineEmits(['toast'])

const {
  startSsh: apiServiceStartSsh,
  stopSsh: apiServiceStopSsh,
  deleteLogger: apiServiceDeleteLogger,
} = useApiService()

const GRACE_PERIOD = 15000

const isOpen = ref(false)
const isSshStartLoading = ref(false)
const isSshStopLoading = ref(false)
const overdue = ref(0)

const overdueTime = computed(() => {
  return (
    Date.now()
    - (props.logger.last_update * 1000
      + GRACE_PERIOD
      + props.logger.update_interval * 1000)
  )
})

const isOnline = computed(() => {
  return overdueTime.value < 0
})

const isSshStarted = computed(() => {
  return !!props.logger.sshport
})
const isSshLoading = computed(() => {
  return isSshStartLoading.value || isSshStopLoading.value
})

const sshCommand = computed(() => {
  return `ssh -o StrictHostKeyChecking=no -o UserKnownHostsFile=/dev/null -p ${props.logger.sshport} ${props.logger.sshuser}@localhost`
})

const sshOperationsButton = computed(() => {
  if (isSshStarted.value && !isSshLoading.value) {
    return {
      text: 'Stop SSH',
      class: 'btn btn-warning',
      handler: stopSsh,
    }
  }
  else if (isSshStartLoading.value) {
    return {
      text: 'Tunnel starting',
      class: 'btn btn-info',
      handler: () => { },
    }
  }
  else if (isSshStopLoading.value) {
    return {
      text: 'Tunnel stopping...',
      class: 'btn btn-info',
      handler: () => { },
    }
  }
  return {
    text: 'Start SSH',
    class: 'btn btn-outline-primary',
    handler: startSsh,
  }
})

watch(
  () => props.logger,
  (newLogger, oldLogger) => {
    if (newLogger.sshport !== oldLogger.sshport) {
      isSshStartLoading.value = false
      isSshStopLoading.value = false
    }
    overdue.value = Math.floor(overdueTime.value / 1000)
  },
)

function isToggleOpen() {
  isOpen.value = !isOpen.value
}

async function startSsh() {
  isSshStartLoading.value = true
  const success = await apiServiceStartSsh(props.logger.mac)
  if (!success) {
    isSshStartLoading.value = false
  }
}
async function stopSsh() {
  isSshStopLoading.value = true
  const success = await apiServiceStopSsh(props.logger.mac)
  if (!success) {
    isSshStopLoading.value = false
  }
}

function removeLogger() {
  apiServiceDeleteLogger(props.logger.mac)
}

async function copySshCommand() {
  await navigator.clipboard.writeText(sshCommand.value)
  emit('toast')
}
</script>

<template>
  <article class="border border-bottom-0 pt-2" :class="{ 'logger-open': isOpen, 'pb-0': !isOpen }">
    <header class="d-flex flex-column justify-content-between custom-flex-row-md">
      <div class="d-flex align-items-start">
        <button data-bs-toggle="collapse" class="btn-open btn btn-primary" @click="isToggleOpen">
          <i class="fas fa-chevron-down" />
        </button>
        <div class="d-flex flex-column ms-3">
          <div class="d-flex align-items-end flex-wrap me-2">
            <h2 class="fs-5 mb-0">
              {{ props.logger.name }}
            </h2>
            <span v-for="tag in props.logger.tags" :key="tag" class="badge bg-secondary rounded-pill mt-1 ms-2">{{ tag
            }}</span>
          </div>
          <span>{{ props.logger.location }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-start flex-sm-shrink-0 mt-2 mt-sm-0">
        <button class="ms-1 rounded-1" :class="sshOperationsButton?.class" @click="sshOperationsButton?.handler">
          <span class="">{{ sshOperationsButton?.text }}</span>
        </button>
        <span :class="{ 'bg-success': isOnline, 'bg-danger': !isOnline }" class="online-status badge ms-1">{{
          isOnline ? "Online" : "Offline"
        }}</span>
      </div>
    </header>
    <div v-if="isSshStarted" :class="isOpen && 'border rounded-1 p-3'" class="d-flex flex-column mb-2">
      <div class="ssh input-group input-group-sm">
        <span class="input-group-text">SSH $:</span>
        <input
          :value="sshCommand" class="form-control has-floating-input-button"
          title="Execute this command on the gateway server"
        >
        <button class=" btn btn-secondary rounded" @click="copySshCommand()">
          <i class="fa fa-clone" />
        </button>
      </div>
      <div v-if="props.logger.ssh_request_user" class="mt-1">
        <span class="fw-bold">Session opened by: </span>
        {{ props.logger.ssh_request_user }}
      </div>
      <div v-if="props.logger.ssh_request_time > 0">
        <span class="fw-bold">Session opened at:</span>
        {{ lightFormat(fromUnixTime(props.logger.ssh_request_time), 'dd.MM.yyyy HH:mm:ss') }}
      </div>
      <LoggerSessionDuration :ssh-request-time="props.logger.ssh_request_time" />
    </div>
    <div v-show="isOpen" class="mt-1">
      <div class="d-flex flex-column justify-content-between custom-flex-row-md border p-3 rounded-1">
        <div class="container-fluid me-2">
          <div class="mac">
            <span class="fw-bold">MAC: </span>{{ props.logger.mac }}
          </div>
          <div class="ip">
            <span class="fw-bold">IP: </span>{{ props.logger.ip }}
          </div>

          <hr class="dropdown-divider" style="height:0.5px">
          <p class="mt-3 mb-1">
            {{ props.logger.info }}
          </p>
        </div>
        <div v-if="!isOnline" class="vr fw-lighter me-4" style="width: 0.5px" />
        <div class="d-flex flex-column align-items-end justify-content-center flex-shrink-0">
          <div v-if="!isOnline" class="border w-100 py-1 px-2 text-center rounded-1">
            <span class="fw-bold">Overdue</span>
            <h5 class="pt-1">
              <span class="badge time-legend d-block pa-2 rounded-1  text-white h5 bg-danger">{{ secondsToTimeFormat(overdue) }}</span>
              <span class="time-legend d-block mt-1">DD:HH:MM:SS</span>
            </h5>
          </div>
          <div v-show="!isOnline" class="mt-3 w-100">
            <button class="btn btn-danger px-4 w-100" @click="removeLogger()">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped>
.time-legend {
  font-size: 15px;
  font-family: monospace;
}
</style>
