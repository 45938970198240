<script setup lang="ts">
import { Toast } from 'bootstrap'

const props = defineProps<{
  triggerToast: boolean
}>()

const emit = defineEmits(['resetToast'])

const toastElement = ref<Element | string>('')
let toast: null | Toast = null

function displayToast() {
  if (toast)
    toast.show()
}

watch(
  () => props.triggerToast,
  (newTrigger, oldTrigger) => {
    if (newTrigger && !oldTrigger) {
      emit('resetToast')
      displayToast()
    }
  },
)

onMounted(() => {
  toast = new Toast(toastElement.value)
})
</script>

<template>
  <div
    ref="toastElement"
    class="toast bg-success text-white border-0 position-fixed bottom-0 start-50 translate-middle-x mb-3"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body">
        <slot />
      </div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      />
    </div>
  </div>
</template>
